export const BUILDING_TYPE_ID = {
  FLAT_ALL: '1',
  FLAT_NEW: '2',
  FLAT_SECONDARY: '3',
  HOUSE: '5',
  OFFICE: '7',
  GARAGE: '8',
  AREA: '9',
  OBJECT: '10'
};

export const urlHashes = {
  authentication: '#authentication',
  more: '#more',
  serviceBump: '#service_bump',
  serviceFeature: '#service_feature',
  serviceVip: '#service_vip',
  restore: '#restore',
  personalAds: '#personal_ads',
  limitPayment: '#limit_payment'
};

export const shortPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip
];

export const longPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip,
  urlHashes.restore,
  urlHashes.personalAds,
  urlHashes.limitPayment
];
